import * as React from "react"
import { Helmet } from "react-helmet"

import { FaFacebook, FaInstagram, FaRegEnvelope } from "react-icons/fa"

import Seo from "../components/seo"
import Layout from "../components/layout"

// markup
const ContactPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Contact - Ensemble Grand Ouest</title>
      </Helmet>
      <Seo title="Contact - Ensemble Grand Ouest"></Seo>
      {/* <header className="header">
        <Titre></Titre>
        <Navigation active="contact"></Navigation>
      </header>
      <main> */}
        <section className="section feuille-conteneur" id="contact">
          <div className="feuille">
            <h2>Restons connectés</h2>
            <div className="flex-conteneur">
              <div className="flex-gauche">
                <p>
                  Contactez-nous pour toute{" "}
                  <em>
                    <b>demande d'information</b>
                  </em>{" "}
                  sur nos événements, pour nous partager un{" "}
                  <em>
                    <b>témoignage</b>
                  </em>{" "}
                  de l'action de Dieu dans votre vie ou pour demander la{" "}
                  <em>
                    <b>prière</b>
                  </em>{" "}
                  :
                </p>
                <div className="bouton-block">
                  <p>
                    <a
                      className="bouton bleu"
                      href="mailto:contact@ensemble-go.fr"
                    >
                      Nous contacter
                    </a>
                  </p>
                  <p>
                    <a
                      className="bouton bleu"
                      href="mailto:temoignage@ensemble-go.fr"
                    >
                      Témoigner
                    </a>
                  </p>
                  <p>
                    <a
                      className="bouton bleu"
                      href="mailto:priere@ensemble-go.fr"
                    >
                      Demander la prière
                    </a>
                  </p>
                </div>
              </div>
              <div>
                <hr className="flex-separateur" />
              </div>
              <div className="flex-droite">
                <p>
                  Vous pouvez aussi connecter avec Ensemble Grand-Ouest par la{" "}
                  <em>
                    <b>lettre de nouvelles</b>
                  </em>{" "}
                  et nous suivre sur nos{" "}
                  <em>
                    <b>réseaux sociaux</b>
                  </em>{" "}
                  :
                </p>
                <div className="bouton-block">
                  <p>
                    <a className="bouton bleu" href="#restons-connectes">
                      <FaRegEnvelope />
                      &nbsp;Newsletter
                    </a>
                  </p>
                  <p>
                    <a className="bouton bleu facebook" href="">
                      <FaFacebook />
                      &nbsp;Facebook
                    </a>
                  </p>
                  <p>
                    <a className="bouton bleu instagram" href="">
                      <FaInstagram />
                      &nbsp;Instagram
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Newsletter></Newsletter>
      </main>
      <Footer></Footer> */}
    </Layout>
  )
}

export default ContactPage
